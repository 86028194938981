// ** React Imports
import { useEffect, useState } from 'react'
import Image from 'next/image'

// ** Next Import
import { useRouter } from 'next/router'

// ** Spinner Import
import Spinner from 'src/@core/components/spinner'

// ** Hook Imports
import { useAuth } from 'src/hooks/useAuth'

import { getAllUserDevice, verifyCurrentType } from '/src/@api/login'

// ui
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useDispatch } from 'react-redux'
import Icon from 'src/@core/components/icon'
import { fetchDeviceUser } from 'src/store/apps/Common'
import { getNotBindOrder, iosLoginReport } from 'src/@api/login'

/**
 *  Set Home URL based on User Roles
 */
export const getHomeRoute = role => {
  if (role === 'client') return '/acl'
  else if (localStorage.getItem('currentMode') === 'Android-Cloud') return '/android/dashboard'
  else return '/dashboard'
}

const Home = () => {
  const { t, i18n } = useTranslation('common')

  //当前页面语种
  const [currentLanguage, setCurrentLanguage] = useState('/')

  useEffect(() => {
    // 函数用于更新 currentLanguage
    const updateLanguage = () => {
      const url = window.location.pathname

      // 使用正则表达式匹配语言代码，包括语言代码后可能没有任何路径的情况
      const match = url.match(/^\/(de|kr|it|pt|jp|fr|es|[a-zA-Z]{2})($|\/)/)
      const language = match ? `/${match[1]}` : '/'
      setCurrentLanguage(language)
    }

    // 初始化时设置语言
    updateLanguage()

    // 监听浏览器历史记录的变化
    window.addEventListener('popstate', updateLanguage)

    // 处理前进和后退操作
    const handleNavigation = () => {
      updateLanguage()
    }

    // 监听 pushState 和 replaceState
    const originalPushState = window.history.pushState
    const originalReplaceState = window.history.replaceState

    window.history.pushState = function () {
      originalPushState.apply(this, arguments)
      handleNavigation()
    }

    window.history.replaceState = function () {
      originalReplaceState.apply(this, arguments)
      handleNavigation()
    }

    // 清理监听器
    return () => {
      window.removeEventListener('popstate', updateLanguage)
      window.history.pushState = originalPushState
      window.history.replaceState = originalReplaceState
    }
  }, []) // 空依赖数组，表示只在组件加载时和卸载时执行一次

  // ** Hooks
  const dispatch = useDispatch()
  const auth = useAuth()
  const router = useRouter()

  let [loading, setLoading] = useState(true)

  const allUserDevice = async () => {
    return await getAllUserDevice({})
  }

  // 校验
  const verify = async type => {
    let allDeviceUser = await allUserDevice()

    if (type == 'iOS-Cloud') {
      // let all = allDeviceUser.data.filter(row => row.platform_type == 'iOS-Cloud')
      let all = [...allDeviceUser.data]

      if (all !== null && all.length > 0 && all[0]) {
        const _data = all.filter(row => row.platform_type === 'iOS-Cloud')

        // 有绑定icloud账号
        if (_data !== null && _data.length > 0 && _data[0]) {
          window.localStorage.setItem(
            'DeviceUser',
            JSON.stringify({
              username: _data[0].iCloud_username,
              password: _data[0].iCloud_password,
              temporary: _data[0].temporary,
              expire_time: _data[0].expire_time,
              likeType: _data[0].like_type
            })
          )

          localStorage.setItem('allDeviceUser', JSON.stringify(all))
          dispatch(fetchDeviceUser(_data[0].iCloud_username))
          localStorage.setItem('Basic', 1)
        } else {
          // 没有绑定icloud账号，验证是否有未绑定账单
          const res = await getNotBindOrder({})
          if (res.data && res.data.length) {
            localStorage.setItem('Basic', 0)
            localStorage.setItem('DeviceUser', JSON.stringify({}))
            localStorage.setItem('allDeviceUser', JSON.stringify(all))
          } else {
            throw new Error('No not bind order')
          }
        }
      } else {
        const res = await getNotBindOrder({})
        if (res.data && res.data.length) {
          localStorage.setItem('Basic', 0)
          localStorage.setItem('DeviceUser', JSON.stringify({}))
          localStorage.setItem('allDeviceUser', JSON.stringify([]))
        } else {
          throw new Error('No not bind order')
        }
      }
    } else if (type.indexOf('Android') >= 0) {
      // let all = allDeviceUser.data.filter(row => row.platform_type.indexOf('Android') >= 0)
      let all = [...allDeviceUser.data]

      if (all !== null && all.length > 0) {
        const _data = all.filter(row => row.platform_type === type)

        window.localStorage.setItem(
          'DeviceUser',
          JSON.stringify({
            username: _data[0]?.iCloud_username,
            password: _data[0]?.iCloud_password,
            temporary: _data[0]?.temporary,
            expire_time: _data[0]?.expire_time,
            likeType: _data[0]?.like_type
          })
        )
        console.log('11', all)
        localStorage.setItem('allDeviceUser', JSON.stringify(all))
        dispatch(fetchDeviceUser(_data[0].iCloud_username))
        localStorage.setItem('Basic', 1)
      } else {
        localStorage.setItem('Basic', 0)
        localStorage.setItem('DeviceUser', JSON.stringify({}))
        localStorage.setItem('allDeviceUser', JSON.stringify([]))
      }
    }
  }

  useEffect(() => {
    if (auth.user && auth.user.role) {
      const homeRoute = getHomeRoute(auth.user.role)

      const type = async () => {
        let { data, order, deviceMany } = await verifyCurrentType()
        localStorage.setItem('deviceMany', deviceMany)

        // 类型
        if (data === 'not bind device' || order.length > 0 || deviceMany) {
          setLoading(false)

          return
        }

        // 定义路径映射
        const paths = {
          'iOS-Cloud': '/dashboard',
          'Android-Cloud': '/android/dashboard',
          'Android-Local': '/android/local/dashboard'
        }

        // 获取路径
        const path = paths[data]

        if (path) {
          // 验证数据
          verify(data).then(() => {
            // 更新 localStorage
            window.localStorage.setItem('currentMode', data)

            // 根据 currentLanguage 决定 fullPath
            const fullPath = currentLanguage === '/' ? path : `${currentLanguage}${path}`
            window.location.assign(fullPath)
          })
        }
      }

      type()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLanguage])

  // 选择平台
  const SelectPlatform = () => {
    return (
      <>
        <h2 className='text-[24px] text-[#000] font-[Poppins] font-[600] leading-[36px] text-center mx-[15px] Mlg:mt-[60px] mt-[78px] mb-[37px]'>
          {t('Select the type of device want to monitoring')}
        </h2>

        {/* <Grid container spacing={6} style={{ marginTop: '30px' }}>
          <Grid item xs={12} md={12} lg={3}></Grid>
          <Grid item xs={12} md={12} lg={3} style={{ padding: '0 3rem', paddingBottom: '24px' }}>
            <Card
              style={{ cursor: 'pointer', background: '#242951', minHeight: '130px' }}
              onClick={() => {
                verify('iOS-Cloud').then(() => {
                  window.localStorage.setItem('currentMode', 'iOS-Cloud')
                  const path = currentLanguage === '/' ? '/dashboard' : `${currentLanguage}/dashboard`
                  window.location.assign(path)
                })
              }}
            >
              <CardContent>
                <div style={{ display: 'flex', justifyContent: 'center', paddingRight: '12px' }}>
                  <Icon icon='ic:baseline-apple' color='#B2BCD2' fontSize='54' />
                </div>
                <div
                  style={{
                    marginTop: '10px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <span style={{ fontSize: '18px', textAlign: 'center', color: '#fff' }}>{t('iOS')}</span>
                  <Icon icon='ic:baseline-navigate-next' color='#fff' />
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={12} lg={3} style={{ cursor: 'pointer', padding: '0 3rem', paddingBottom: '24px' }}>
            <Card
              style={{ cursor: 'pointer', background: '#242951', minHeight: '130px' }}
              onClick={async () => {
                console.log('12')

                // 获取所有设备用户
                const allDeviceUser = await allUserDevice()

                // 检查数据是否是数组
                const data = Array.isArray(allDeviceUser.data) ? allDeviceUser.data : []

                // 确定要跳转的路径
                let path = '/android/classification' // 默认路径

                if (data.some(row => row.platform_type === 'Android-Cloud')) {
                  verify('Android-Cloud').then(() => {
                    window.localStorage.setItem('currentMode', 'Android-Cloud')
                    path = '/android/dashboard'

                    // 拼接完整路径
                    const fullPath = currentLanguage === '/' ? path : `${currentLanguage}${path}`

                    // 跳转到目标路径
                    window.location.assign(fullPath)
                  })

                  return
                } else if (data.some(row => row.platform_type === 'Android-Local')) {
                  verify('Android-Local').then(() => {
                    window.localStorage.setItem('currentMode', 'Android-Local')
                    path = '/android/local/dashboard'

                    // 拼接完整路径
                    const fullPath = currentLanguage === '/' ? path : `${currentLanguage}${path}`

                    // 跳转到目标路径
                    window.location.assign(fullPath)
                  })

                  return
                }

                window.localStorage.setItem('currentMode', 'Android-Cloud')

                // 拼接完整路径
                const fullPath = currentLanguage === '/' ? path : `${currentLanguage}${path}`

                // 跳转到目标路径
                window.location.assign(fullPath)
              }}
            >
              <CardContent>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingRight: '12px',
                    height: '54px'
                  }}
                >
                  <Icon icon='bi:android2' color='#B2BCD2' fontSize='40' />
                </div>
                <div
                  style={{
                    marginTop: '10px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <span style={{ fontSize: '18px', textAlign: 'center', color: '#fff' }}>{t('Android')}</span>
                  <Icon icon='ic:baseline-navigate-next' color='#fff' />
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={12} lg={3}></Grid>
        </Grid> */}

        {/* 新ui */}
        <div className='max-w-[728px] grid grid-cols-1 smjs:grid-cols-2 gap-[30px] mx-[6px] smjs:mx-auto'>
          <div
            className='w-full h-[130px] cursor-pointer flex flex-col justify-center items-center [border:1px_solid_#0481FF] rounded-[10px]
          [background:linear-gradient(180deg,#ECF3FE_0%,#FDFEFF_100%)] hover:[background:linear-gradient(180deg,#D9E8FF_0%,#ECF3FE_100%)]'
            onClick={() => {
              // 上传ios日志
              iosLoginReport({
                "platform": "ios-icloud",
                "site": "spyx",
                "s_1": 1
              })


              verify('iOS-Cloud').then(() => {
                window.localStorage.setItem('currentMode', 'iOS-Cloud')
                const path = currentLanguage === '/' ? '/dashboard' : `${currentLanguage}/dashboard`
                window.location.assign(path)
              })
            }}
          >
            <Image src='/images/bind/ios.svg' alt='ios' width={41} height={48} className='w-[41px] h-[48px]' />
            <div className='flex justify-center items-center mt-[9px]'>
              <span className='text-[20px] text-[#222222] font-[Poppins] font-bold leading-[30px]'>{t('iOS')}</span>
              <Image src='/images/bind/arrow.svg' alt='arrow' width={6} height={12} className='w-[6.01px] h-[11.66px] ml-2' />
            </div>
          </div>

          <div
            className='w-full h-[130px] cursor-pointer flex flex-col justify-center items-center [border:1px_solid_#0481FF] rounded-[10px]
          [background:linear-gradient(180deg,#ECF3FE_0%,#FDFEFF_100%)] hover:[background:linear-gradient(180deg,#D9E8FF_0%,#ECF3FE_100%)]'
            onClick={async () => {

              // 获取所有设备用户
              const allDeviceUser = await allUserDevice()

              // 检查数据是否是数组
              const data = Array.isArray(allDeviceUser.data) ? allDeviceUser.data : []

              // 确定要跳转的路径
              let path = '/android/classification' // 默认路径

              if (data.some(row => row.platform_type === 'Android-Cloud')) {
                verify('Android-Cloud').then(() => {
                  window.localStorage.setItem('currentMode', 'Android-Cloud')
                  path = '/android/dashboard'

                  // 拼接完整路径
                  const fullPath = currentLanguage === '/' ? path : `${currentLanguage}${path}`

                  // 跳转到目标路径
                  window.location.assign(fullPath)
                })

                return
              } else if (data.some(row => row.platform_type === 'Android-Local')) {
                verify('Android-Local').then(() => {
                  window.localStorage.setItem('currentMode', 'Android-Local')
                  path = '/android/local/dashboard'

                  // 拼接完整路径
                  const fullPath = currentLanguage === '/' ? path : `${currentLanguage}${path}`

                  // 跳转到目标路径
                  window.location.assign(fullPath)
                })

                return
              }

              window.localStorage.setItem('currentMode', 'Android-Cloud')

              // 拼接完整路径
              const fullPath = currentLanguage === '/' ? path : `${currentLanguage}${path}`

              // 跳转到目标路径
              window.location.assign(fullPath)
            }}
          >
            <Image src='/images/bind/android.svg' alt='android' width={42} height={50} className='w-[42.31px] h-[50px]' />
            <div className='flex justify-center items-center mt-[9px]'>
              <span className='text-[20px] text-[#222222] font-[Poppins] font-bold leading-[30px]'>{t('Android')}</span>
              <Image src='/images/bind/arrow.svg' alt='arrow' width={6} height={12} className='w-[6.01px] h-[11.66px] ml-2' />
            </div>
          </div>
        </div>
      </>
    )
  }

  return <>{loading ? <Spinner sx={{ height: '100%' }} /> : <SelectPlatform />}</>
}

export default Home

export async function getStaticProps({ locale }) {
  if (locale === 'jp') {
    locale = 'ja'
  } else if (locale === 'kr') {
    locale = 'ko'
  } else if (locale === 'it-ch') {
    locale = 'it'
  }

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common']))

      // Will be passed to the page component as props
    }
  }
}
